<template lang="pug">
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'GamesList',
  props: {
    defaultLimit: {
      type: Number,
      default: 10,
      required: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
      required: false,
    },
    page: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    localSearchString: null,
    //Указатель на корневой элемент СВГ обложки
    svg: null,
    //Таймер для переключения анимаций обложек
    timer: null,
    //Флаг наведения
    hover: false,
    //Список фильтров
    filters: [
      {name: 'Slots', title: 'slotsLabel'},
      {name: 'Pyramid', title: 'pyramidLabel'},
      {name: 'Blackjack', title: 'blackjackLabel'},
      {name: 'Bingo', title: 'bingoLabel'},
      {name: 'Roulette', title: 'rouletteLabel'},
      {name: 'Mini games', title: 'miniGamesLabel'}
    ],
    //Флаг отображения фильтров
    isFiltersShow: false,
    //Выбранная обложка
    gameSelectId: 0,
    isMobile: false,
    isClickedCross: false,
  }),
  computed: {
    ...mapGetters({
      userLanguageCode: 'user/getLanguageCode',
      list: 'games/getList',
      listCount: 'games/getListCount',
      token: 'games/getLaunchToken',
      isFavoritesFilter: 'games/isFavoritesFilter',
      isNewFilter: 'games/isNewFilter',
      searchString: 'games/getSearchString',
      additionalFilters: 'games/getAdditionalFilters',
      listLimit: 'games/getListLimit',
      isAuthUser: 'auth/isAuthUser',
      pages: 'main/getPages',
      brand: 'main/getBrand',
    }),
    isBtnShow() {
      return this.listLimit < this.listCount;
    },
  },
  async mounted() {
    this.SET_LIST_LIMIT(this.defaultLimit);
    if (this.page==='Main') {
      this.SET_SEARCH_STRING('');
    } else {
      this.localSearchString = this.searchString;
    }
    if(!this.pages.games) {
      this.SET_NEW_FILTER(false);
      this.SET_FAVORITES_FILTER(false);
      this.SET_ADDITIONAL_FILTERS('');
    }

    await this.fetch();
    window.addEventListener('orientationchange', this.resize, false);
    window.addEventListener('resize', this.resize, false);
  },
  methods: {
    ...mapActions({
      fetchGameList: 'games/fetchGameList',
      doChangeFavorites: 'games/doChangeFavorites',
      fetchLaunchToken: 'games/fetchLaunchToken',
    }),
    ...mapMutations({
      SET_LAUNCH_TOKEN: 'games/SET_LAUNCH_TOKEN',
      SET_LIST_LIMIT: 'games/SET_LIST_LIMIT',
      SET_NEW_FILTER: 'games/SET_NEW_FILTER',
      SET_FAVORITES_FILTER: 'games/SET_FAVORITES_FILTER',
      SET_ADDITIONAL_FILTERS: 'games/SET_ADDITIONAL_FILTERS',
      SET_SEARCH_STRING: 'games/SET_SEARCH_STRING',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_DEMO_RUN_GAME: 'games/SET_DEMO_RUN_GAME',
    }),
    filtersResetListener() {
      this.SET_LIST_LIMIT(this.defaultLimit);
      if (this.additionalFilters === '')
        {
          const filters_arr = [];
          this.filters.forEach(
            (filter_obj)=> {
              filters_arr.push(filter_obj.name);
            }
          );
          this.SET_ADDITIONAL_FILTERS(filters_arr.join(','));
        }
          else
        this.SET_ADDITIONAL_FILTERS('');
      this.fetch();
    },
    resize() {
      if (window.innerWidth >= 1280) {
        this.isMobile = false;
      }
      else {
        this.isMobile = true;
      }

      const el_wrap = this.$refs['games_wrap'];
      if (el_wrap) {
        const width_wrap = el_wrap.offsetWidth;
        const el_covers = el_wrap.querySelectorAll(':scope .gl-card');
        if (el_covers) {
          const covers_count = el_covers.length;
          const isLast = !this.isBtnShow;
          let cols_count = 0;
          let width_cover = 0;
          let cover_show_count = 0;
          let cover_index_max = 0;
          el_covers.forEach(
              (el_cover, index) => {
                if (width_cover === 0) {
                  width_cover = el_cover.offsetWidth;
                  cols_count = (width_wrap / width_cover) | 0;
                  cover_show_count = (covers_count / cols_count) | 0;
                  cover_index_max = cover_show_count * cols_count;
                }
                el_cover.classList.toggle('dhide', isLast === false && cover_index_max > 0 && index >= cover_index_max);
              }
          );
        }
      }
    },
    async fetch() {
      this.icon_stop();
      await this.fetchGameList();
      this.resize();
    },
    buildGameUrl(gameId, isDemo = false) {
      var searchParams = new URLSearchParams(
          {
            r: 'x-slots/start-game-no-cookie',
            demo: 0,
            homeless: 0
          }
      );
      searchParams.append('game_id', gameId);
      searchParams.append('lang', this.userLanguageCode);
      searchParams.append('back_url', window.location.href);
      searchParams.append('partner_id', process.env.VUE_APP_PARENT_ID);
      if (isDemo === true)
        searchParams.append('demo', 1);
      else
        searchParams.append('token', this.token);

      return 'http://wbgdev.ngmcasino.com/wbg_server/index.php?' + searchParams.toString();
    },
    url_icon(image_file) {
      let out = 'game-icons/no_cover.svg';
      if (image_file !== null)
        try {
          out = 'game-icons/' + image_file;
        } catch (e) {
          console.log('Cover not find: ', image_file);
        }
      return out;
    },
    icon_timer_create() {
      if (this.timer === null)
        this.timer = setTimeout(
            () => {
              this.icon_timer();
            },
            10000
        );
    },
    icon_timer(rand = true) {
      if (this.timer !== null)
        clearTimeout(this.timer);
      this.timer = null;
      if (rand === true)
        this.icon_random();
    },
    icon_play() {
      if (
          this.svg &&
          this.svg !== null &&
          this.svg.unpauseAnimations &&
          this.svg.animationsPaused &&
          this.svg.animationsPaused() === true
      )
        this.svg.unpauseAnimations();
      this.icon_timer_create();
    },
    icon_stop() {
      if (
          this.svg &&
          this.svg !== null &&
          this.svg.pauseAnimations &&
          this.svg.animationsPaused() === false
      )
        this.svg.pauseAnimations();
      this.svg = null;
      this.icon_timer_create();
    },
    icon_random() {
      if (this.hover === false) {
        const icons = this.list.filter(icon => icon.image_file !== null);
        this.icon_stop();
        if (icons.length > 0) {
          //eslint-disable-next-line
          const icon = icons[Math.floor(Math.random() * icons.length)];
          const el_array = this.$refs['game_' + icon.id];
          if (el_array && el_array.length > 0) {
            const el = el_array[0];
            if (el) {
              if (el.contentDocument === null)
                this.icon_random();
              else {
                this.svg = el.contentDocument.documentElement;
                this.icon_play();
              }
            }
          }
        }
      }
    },
    async on_over(event) {
      this.hover = true;
      this.icon_timer(false);
      this.icon_stop();
      this.svg = event.target.firstChild.contentDocument.documentElement;
      this.icon_play();
    },
    async on_out() {
      this.hover = false;
      this.icon_random();
    },
    async on_svg_load(event) {
      if (this.svg === null) {
        this.icon_stop();
        this.svg = event.target.contentDocument.documentElement;
      } else {
        const svg_event = event.target.contentDocument.documentElement;
        if (
            svg_event &&
            svg_event.pauseAnimations &&
            svg_event.animationsPaused() === false
        )
          svg_event.pauseAnimations();
      }
    },
    async doOpenGameUrl(gameId, isDemo = false) {
      if (isDemo === true || !this.isAuthUser)
        window.location.href = this.buildGameUrl(gameId, true);
      else {
        await this.fetchLaunchToken(gameId);
        if (this.token.length > 0) {
          const url_game_run = this.buildGameUrl(gameId, false);
          this.SET_LAUNCH_TOKEN('');
          window.location.href = url_game_run;

          if (this.$metrika) {
            this.$metrika.reachGoal('gameRun');
          }
        }
        this.SET_LAUNCH_TOKEN('');
      }
    },
    async on_click_view_more() {
      if (this.page === 'Main') {
        this.SET_OPEN_PAGE('games');
        this.$router.push('/games');
        window.scrollTo(0, 0);
      } else {
        this.SET_LIST_LIMIT(this.listLimit + this.defaultLimit);
        this.fetch();
      }
    },
    async toggleChacked(index) {
      if (
          typeof this.filters[index] === 'object' &&
          this.filters[index] != null
      ) {
        const filters_names = (
            this.additionalFilters === '' ?
                [] :
                this.additionalFilters.split(',')
        );
        const filter_index = filters_names.indexOf(this.filters[index].name);
        let newAdditionalFilters = '';

        if (filter_index < 0) {
          filters_names.push(this.filters[index].name);
          newAdditionalFilters = filters_names.join(',');
        } else
          newAdditionalFilters = filters_names.filter(
              function (value, index_one) {
                return index_one != filter_index;
              }
          ).join(',');

        this.SET_LIST_LIMIT(this.defaultLimit);
        this.SET_ADDITIONAL_FILTERS(newAdditionalFilters);
        this.fetch();
      }
    },
    async setFavorite(game_id, is_favorite) {
      await this.doChangeFavorites(
          {
            id: game_id,
            value: is_favorite
          }
      );
      if (this.isFavoritesFilter && !is_favorite) {
        this.fetch();
      }
    },
    calcChacked(index) {
      let out = false;
      if (
          typeof this.filters[index] === 'object' &&
          this.filters[index] != null
      )
        out = this.additionalFilters.split(',').includes(this.filters[index].name);

      return out;
    },
    //Обработчик выбора обложки
    selectGame(gameId = 0, isDemo = false) {
      if (!this.isMobile) {
        if (isDemo) {
          this.SET_DEMO_RUN_GAME(true);
        }
        this.doOpenGamePage(gameId);
      } else {
        if (this.gameSelectId !== gameId) {
          this.gameSelectId = gameId;
        } else {
          if (!isDemo && this.$metrika) {
            this.$metrika.reachGoal('gameRun');
          }
          this.doOpenGameUrl(gameId, isDemo);
        }
      }
    },
    keypressSearch(e) {
      if (e.key === 'Enter') {
        this.searchListener();
      }
    },
    favListener() {
      this.SET_LIST_LIMIT(this.defaultLimit);
      this.SET_FAVORITES_FILTER(!this.isFavoritesFilter);
      if (this.isFavoritesFilter && this.isNewFilter){
        this.SET_NEW_FILTER(!this.isNewFilter);
      }

      this.fetch();
    },
    newListener() {
      this.SET_LIST_LIMIT(this.defaultLimit);
      this.SET_NEW_FILTER(!this.isNewFilter);
      if (this.isFavoritesFilter && this.isNewFilter){
        this.SET_FAVORITES_FILTER(!this.isFavoritesFilter);
      }
      this.fetch();
    },
    searchListener() {
      this.SET_LIST_LIMIT(this.defaultLimit);
      this.SET_SEARCH_STRING(this.localSearchString);
      this.fetch();
    },
    handlerGlBtnFilters() {
      this.isFiltersShow=!this.isFiltersShow;

      if (this.$metrika) {
        this.$metrika.reachGoal('gamesFilterClick');
      }
    },
    async doOpenGamePage(gameId) {
      if (!this.isMobile) {
        this.SET_OPEN_PAGE('personalGame');
        await this.$router.push(`/game?id=${gameId}`);
        window.scrollTo(0, 0);
      }
    },
    /**
     * метод-обработчик очистки строки поиска
     * @returns {Promise<void>}
     */
    async clearSearchStr() {
      this.isClickedCross = true;
      this.localSearchString = '';
      this.SET_SEARCH_STRING('');
      await this.fetchGameList();
      setTimeout(() => {
        this.isClickedCross = false;
      }, 500);
    },
    calcChackedReset () {
      let out = true;
           const filters_arr = this.additionalFilters.split(',');
           this.filters.forEach(
              (filter_obj)=> {
                if (out === true && filters_arr.includes(filter_obj.name) === false)
                     out = false;
               }
           );
      return out;
    },
  },
  watch: {
    searchString(newSearch) {
      if (this.localSearchString !== newSearch) {
        this.localSearchString = newSearch;
        this.fetch();
      }
    },
  }
};
</script>

<style lang="scss">
</style>