<template lang="pug">
  .gl
    .gl-head(v-if="showFilters")
      .gl-row-1
        .gl-col-2
          input.gl-search-input(:ref="'gl-search-input'"
            v-model="localSearchString" :placeholder="$t('games.searchPlaceholder')"
            @keypress="keypressSearch")
          .gl-btn-search(@click="searchListener")
          .head__close(v-show="localSearchString != null && localSearchString !== ''" @click="clearSearchStr")
            CrossShadowOff(:isClickedCross="isClickedCross" :width="16" :height="16")

        .gl-col-1
          .gl-btn-favorites(:class="{'gl-btn_selected': isFavoritesFilter}" @click="favListener()") {{ $t('games.favoritesButton') }}
            img(:src="require('@/assets/images/games/sunduk-white/icon_favorites.svg')")
          .gl-btn-new(:class="{'gl-btn_selected': isNewFilter}" @click="newListener()") {{ $t('games.newButton') }}
            img(:src="require('@/assets/images/games/sunduk-white/icon_new.svg')")
          .gl-btn-filters(v-if="!brand.slot_games_only" :class="{'gl-btn_selected': isFiltersShow}" @click="handlerGlBtnFilters") {{ $t('games.filtesButton') }}
            img(:src="require('@/assets/images/games/sunduk-white/icon_filters.svg')")
      .gl-row-2(v-if="!brand.slot_games_only" v-show='isFiltersShow')
        .gl-btns-wrap
          label.gl-btn-reset(@click="filtersResetListener()")
            .gl-checkbox(:class="{'gl-checkbox_selected': calcChackedReset()}")
            .gl-label {{ $t('games.resetButton') }}

        .gl-filters-wrap
          label(v-for='(filter_data,index) in filters' @click="toggleChacked(index)")
            .gl-checkbox(:class="{'gl-checkbox_selected': calcChacked(index)}")
            .gl-label {{ $t('games.'+filter_data.title) }}

    .gl-wrap.icons(:ref="'games_wrap'")
      .gl-card(v-for='(icon,index) in list' :key='icon.id' @click.stop="selectGame(icon.id, false)"
              :class="{'gl-card_selected': (icon.id === gameSelectId)}"  )
        .gl-btn-demo( @click.stop="selectGame(icon.id, true)") {{ $t('games.demoButton') }}
        .gl-btn-new
        .gl-btn-favorites( :class="{'gl-btn_selected': icon.is_favorite}" @click.stop="setFavorite(icon.id, !icon.is_favorite)")
        .gl-card_image(:data-titleIcon="icon.title" @mouseover="on_over" @mouseout="on_out")
          object.icon(type="image/svg+xml" :data-game_id="'game_'+icon.id" :ref="'game_'+icon.id" :data="url_icon(icon.image_file)" @load="on_svg_load" )
    .gl-wrap(v-show='isBtnShow')
      .gl-btn-more.d-flex( @click="on_click_view_more" ) {{ page === 'Main' ? $t('games.allButton') : $t('games.moreButton') }}
</template>

<script>

const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');

import GamesList from './GamesList';

export default {
  extends: GamesList,
  components: {
    CrossShadowOff,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/games/games-list_sw.scss";
</style>